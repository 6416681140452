import { Link } from 'react-router-dom';
import { Logo } from '..';
import { FOOTER_LINKS } from '../../constants/HomePage';
import data from '../../data/footer.json';
import regions from '../../data/region.json';
import { motion } from 'framer-motion';

export default function Footer() {
  const handleNavigateToBlog = (url) => {
    window.open(url, '_blank');
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const linkVariants = {
    hidden: { x: -50, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
  };

  const iconVariants = {
    hidden: { x: 50, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
    hover: { rotate: 20, scale: 1.1 },
  };

  const handleNavigateToMain = (url) => {
    window.open(url, '_blank');
  };

  return (
    <footer className="padding-container">
      <div className="flex items-start justify-between mt-20">
        <div>
          <Logo height={70} width={296} />
          <div className="mt-4">
            {regions.map((region) => (
              <div
                key={region.id}
                className="flex items-center text-base font-normal leading-5 cursor-pointer"
                onClick={() => {
                  handleNavigateToMain(region.href);
                }}
              >
                <img
                  src={region.icon}
                  alt={region.name}
                  className="w-12 h-20 object-contain object-center"
                />
                <span className="text-gray-300 ml-8">{region.name}</span>
              </div>
            ))}
          </div>
        </div>
        <motion.div
          className="grid grid-cols-2 xl:grid-cols-3 gap-20 lg:gap-32"
          initial="hidden"
          whileInView="visible"
          variants={containerVariants}
        >
          {FOOTER_LINKS.map((value, index) => (
            <motion.article key={index} variants={linkVariants}>
              <div className="mb-10">
                <p className="footer-text-topic">{value.title}</p>
              </div>
              <motion.div className="footer-text-subtopic">
                {value.links.map((link, linkIndex) => (
                  <motion.div
                    key={linkIndex}
                    className="mb-4"
                    variants={linkVariants}
                    whileHover={{ scale: 1.05, color: '#F5F5F5' }}
                  >
                    {link.type === 'email' ? (
                      <Link to={link.url}>{link.name}</Link>
                    ) : link.type === 'tel' ? (
                      <Link to={link.url}>{link.name}</Link>
                    ) : typeof link === 'string' ? (
                      link
                    ) : link.type === 'internal' ? (
                      <Link to={link.url}>{link.name}</Link>
                    ) : (
                      link.type === 'external' && (
                        <Link to={`state/${link.url}`}>{link.name}</Link>
                      )
                    )}
                  </motion.div>
                ))}
              </motion.div>
            </motion.article>
          ))}
        </motion.div>
      </div>
      <hr className="mt-20 border-t-[0.5px] border-gray-600" />
      <div className="flex items-center justify-between mt-10">
        <div className="footer-text-subtopic">Copyrights © 2024 Medfuture</div>
        <motion.div
          className="flex items-center gap-6"
          initial="hidden"
          whileInView="visible"
          variants={containerVariants}
        >
          {data.map((city, index) => (
            <motion.div
              key={city.id}
              custom={index}
              variants={iconVariants}
              whileHover="hover"
              onClick={() => {
                handleNavigateToBlog(city.href);
              }}
            >
              <img
                src={city.icon}
                alt="avatar"
                width={40}
                height={40}
                className="rounded-full cursor-pointer"
              />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </footer>
  );
}
