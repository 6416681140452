export const PERMANENT_CONTACT_INFORMATION = [
  {
    id: 1,
    name: 'Doctor Division',
    call: '61 (02) 6189 8040',
    email: 'locum@medfuture.com.au',
    link: '',
  },
  {
    id: 2,
    name: 'GP Divison',
    call: '61 (03) 7065 4322',
    email: 'gprecruitment@medfuture.com.au',
    link: '',
  },
  {
    id: 3,
    name: 'SIMG Division',
    call: '61 (02) 6189 8040',
    email: 'simg@themedfuture.com',
    link: '',
  },
  {
    id: 4,
    name: 'Oral Health Division',
    call: '61 (02) 6189 8151',
    email: 'dentists@medfuture.com.au',
    link: '',
  },
  {
    id: 5,
    name: 'Mental Health Division',
    call: '61 (03) 7065 4348',
    email: 'psychologists@medfuture.com.au',
    link: '',
  },
  {
    id: 6,
    name: 'AHP Division',
    call: '61 (02) 6189 8151',
    email: 'AHP@medfuture.com.au',
    link: '',
  },
  {
    id: 7,
    name: 'Nursing Division',
    call: '+44 (0) 1908 552820',
    email: 'nursing@medfuture.com.au',
    link: '',
  },
  {
    id: 8,
    name: 'Premier Locum',
    call: '61 (02) 6188 5739',
    email: 'PremierGP@medfuture.com.au',
    link: '',
  },
  {
    id: 9,
    name: 'Locum Division',
    call: '61 (02) 6188 5739',
    email: 'locum@medfuture.com.au',
    link: '',
  },
];

export const LOCUM_CONTACT_INFORMATION = [
  {
    id: 1,
    name: 'Permanent Recruitment',
    call: '61 (03) 7065 4322',
    email: 'gprecruitment@medfuture.com.au',
    link: '',
  },
  {
    id: 2,
    name: 'Temporary Recruitment',
    call: '61 (02) 6188 5739',
    email: 'locum@medfuture.com.au',
    link: '',
  },
  {
    id: 3,
    name: 'Locum Doctor Booking',
    call: '61 (02) 6188 5739',
    email: 'locum@medfuture.com.au',
    link: '',
  },
  {
    id: 4,
    name: 'Agency Nursing Booking',
    call: '61 (02) 6188 5739',
    email: 'locum@medfuture.com.au',
    link: '',
  },
  {
    id: 5,
    name: 'Specialist International Medical Graduate Recruitment',
    call: '61 (02) 8527 0686',
    email: 'Clientservices@mdfuture.com.au',
    link: '',
  },
  {
    id: 6,
    name: 'International Allied Health Recruitment',
    call: '61 (02) 8527 0686',
    email: 'Clientservices@medfuture.com.au',
    link: '',
  },
  {
    id: 7,
    name: 'Nursing Locum Services',
    call: '+44 (0) 1908 552820',
    email: 'nursing@medfuture.com.au',
    link: '',
  },
  {
    id: 8,
    name: 'Outsource your Recruitment',
    call: '+44 (0) 1908 552820',
    email: 'Clientservices@medfuture.com.au',
    link: '',
  },
  {
    id: 9,
    name: 'Emergency Doctor Services',
    call: '61 (02) 6188 5739',
    email: 'locum@medfuture.com.au',
    link: '',
  },
  {
    id: 10,
    name: 'International Recruitment',
    call: '61 (02) 8527 0686',
    email: 'Clientservices@medfuture.com.au',
    link: '',
  },
];
