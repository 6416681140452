import {
  BannerComponent,
  Button,
  DropDownSection,
  HeroBackground2,
  InsightComponent,
} from '@/components';
import MedfutureBenefits from '@/components/employerService/MedfutureBenefits';
import MedfutureRoundCaresoul from '@/components/employerService/MedfutureRoundCaresoul';
import EmployerServiceRegistration from '@/components/forms/employerService/EmployerServiceRegistration';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';
import useResponsive from '@/hooks/useResponsive';
import { MobileLayout, PageLayout } from '@/layout';

export default function PermanentRecruitment() {
  const { isMobile } = useResponsive();
  return (
    <>
      {isMobile ? (
        <>
          <MobileLayout>
            {/* <DynamicMobileHero /> */}
            {/* <MobileDescription />
        <MobileAwardBanner />
        <DynamicJobPortal data={JobsData} />
        <MobileOfferDropDown data={OfferData} />
        <MobileRegister />
        <MobileInsight data={InsightData} /> */}
          </MobileLayout>
        </>
      ) : (
        <>
          <HeroBackground2
            title={'Permanent Recruitment Services'}
            description={
              'Discover unparalleled medical recruitment solutions tailored to meet the unique needs of healthcare employers.'
            }
            icon={''}
            banner={`${AWS_BASE_URL_ASSETS}/PermanentRec.png`}
            bannerAlt={'bannerAlt'}
          />
          <BannerComponent />
          <PageLayout>
            <div className="text-center font-sans font-normal text-[33px] lg:text-[24px] leading-[48px] lg:leading-[36px] text-blue-350 padding-container">
              Medfuture offers specialised medical recruitment services for
              healthcare employers, including permanent, temporary, and
              international staffing solutions. Optimise your workforce with our
              dedicated recruitment expertise.
            </div>
            <MedfutureRoundCaresoul />
            <div className="text-center font-sans font-normal text-[33px] lg:text-[24px] leading-[48px] lg:leading-[36px] text-blue-350 padding-container">
              Medfuture offers specialised medical recruitment services for
              healthcare employers, including permanent, temporary, and
              international staffing solutions. Optimise your workforce with our
              dedicated recruitment expertise.
            </div>
            <div className="padding-container">
              <h1 className="font-sans font-bold text-center text-gray-800 text-[32px] leading-[44px] mb-16">
                Our Solution
              </h1>
              <div className="grid grid-cols-2 gap-x-5 ">
                <div className="group relative cursor-pointer bg-gray-510 h-[331px] p-10">
                  <div className="relative z-[4]">
                    <h1 className="font-sans font-bold text-gray-800 group-hover:text-white text-[26px] leading-[35px]">
                      Complete Recruitment Solution
                    </h1>
                    <p className="font-sans font-normal text-lg leading-[34px] text-blue-350 group-hover:text-white mt-10">
                      Needs Assessment We begin by conducting a thorough
                      assessment of your staffing needs,
                    </p>
                    <div className="w-[202px] mt-10">
                      <Button
                        type="button"
                        title="Contact Us"
                        variant="find-jobs-btn"
                      />
                    </div>
                  </div>
                  <div className="absolute inset-0 bg-blue-150 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700 ease-in-out"></div>
                </div>
                <div className="group cursor-pointer relative bg-gray-510 h-[331px] p-10">
                  <div className="relative z-[4]">
                    <h1 className="font-sans font-bold text-gray-800 group-hover:text-white text-[26px] leading-[35px]">
                      Adobe Permeant Recruitment Solution
                    </h1>
                    <p className="font-sans font-normal text-lg leading-[34px] text-blue-350 group-hover:text-white  mt-10">
                      Needs Assessment We begin by conducting a thorough
                      assessment of your staffing needs,
                    </p>
                    <div className="w-[202px] mt-10">
                      <Button
                        type="button"
                        title="Contact Us"
                        variant="find-jobs-btn"
                      />
                    </div>
                  </div>
                  <div className="absolute inset-0 bg-blue-150 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700 ease-in-out"></div>
                </div>
                <div></div>
              </div>
            </div>
            <EmployerServiceRegistration />
            <MedfutureBenefits />
            <DropDownSection />
            {/* <InsightComponent /> */}
          </PageLayout>
        </>
      )}
    </>
  );
}
