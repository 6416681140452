import React, { useState } from 'react';
import Slider from 'react-slick';
import { slickSettings } from '@/lib/Caresoul/CaresoulMainSettings';
import { AWS_BASE_URL_SPECIALTY } from '@/config/seviceApiConfig';
import { formatNameForURL } from '@/utils/formatNameForURL';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

interface SpecialtyItem {
  specialities_id: number;
  name: string;
  image: string;
  image_alt: string;
}

interface SpecialtyDataProps {
  data: SpecialtyItem[];
}

const SpecialtyData: React.FC<SpecialtyDataProps> = ({ data }) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const settings = slickSettings({ activeIndex, setActiveIndex });
  const [jobCounts, setJobCounts] = useState<{ [key: number]: number }>({});

  // useEffect(() => {
  //   const fetchJobCounts = async () => {
  //     const counts: { [key: number]: number } = {};

  //     for (const item of data) {
  //       const { filterData, FilterDataLoading, FilterDataError } =
  //         useFetchFilterJobDetails({ specialtyId: item.specialities_id });

  //       if (!FilterDataLoading && !FilterDataError && filterData) {
  //         counts[item.specialities_id] = filterData.data.length || 0;
  //       } else {
  //         counts[item.specialities_id] = 0;
  //       }
  //     }

  //     setJobCounts(counts);
  //   };

  //   fetchJobCounts();
  // }, [data]);

  const filteredData = data
    ?.filter((item) => item.job_count >= 0)
    .sort((a, b) => b.job_count - a.job_count);

  // if (!filteredData || filteredData.length === 0) {
  //   return (
  //     <div className="flex items-center justify-center font-sans font-semibold text-[24px]">
  //       No Jobs are available
  //     </div>
  //   );
  // }

  const handleNavigateToFilter = (specialty: string) => {
    navigate(`/specialty/permanent/${specialty}-jobs-in-newzealand`);
  };

  const imageVariants = {
    hover: {
      scale: 1.03,
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <Slider {...settings}>
      {filteredData.map((item) => (
        <motion.div
          key={item.specialities_id}
          className="cursor-pointer"
          onClick={() =>
            handleNavigateToFilter(
              formatNameForURL(item.short_name.replace(/jobs/i, '').trim())
            )
          }
          whileHover="hover"
        >
          <div className="m-1">
            <motion.img
              src={`${AWS_BASE_URL_SPECIALTY}${item.image}`}
              alt={item.image_alt}
              width="355"
              height="255"
              className="object-cover"
              variants={imageVariants}
            />
            <div className="mt-5">
              <h3 className="specialty-text-main mb-1">
                {item.speciality_name}
              </h3>
              <p className="specialty-text-sub">{item.job_count} Jobs</p>
            </div>
          </div>
        </motion.div>
      ))}
    </Slider>
  );
};

export default SpecialtyData;
