import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { blurImageProps } from '../types/types';

const BlurryLoadingImage = ({
  preview,
  image,
  alt,
  imageStyleClass,
  divStyleClass,
  bgColor = 'transparent',
}: blurImageProps) => {
  const [currentImage, setCurrentImage] = useState<string>(preview);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchImage = (src: string) => {
    const loadingImage = new Image();
    loadingImage.src = src;
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
      setLoading(false);
    };
  };

  useEffect(() => {
    fetchImage(image);
  }, [image]);

  return (
    <motion.figure
      className={divStyleClass}
      whileHover={{ scale: 1.06 }}
      transition={{ duration: 1 }}
    >
      <img
        style={{
          filter: `${loading ? 'blur(20px)' : ''}`,
          transition: '0.8s filter linear',
          width: '100%',
          background: bgColor,
        }}
        src={currentImage}
        alt={alt}
        className={imageStyleClass}
      />
    </motion.figure>
  );
};

export default BlurryLoadingImage;
