import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import ClientImg1 from '@/assets/icons/Rectangle 654.png';
import ClientImg2 from '@/assets/icons/Rectangle 655.png';
import ClientImg3 from '@/assets/icons/Rectangle 656.png';
import ClientImg4 from '@/assets/icons/Rectangle 657.png';

const images = [ClientImg1, ClientImg2, ClientImg3, ClientImg4];
const repeatedImages = [...images, ...images, ...images];

const hoverVariant = {
  hover: {
    scale: 1.1,
    rotate: 5,
    transition: {
      duration: 0.3,
    },
  },
};

export default function BannerComponent() {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1536);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="max-width-container bg-gray-320 overflow-hidden relative">
      <div className="main-width-container overflow-hidden relative p-7">
        <motion.div
          className={`flex ${isLargeScreen ? 'scrolling-container' : ''}`}
          style={{
            cursor: 'pointer',
          }}
        >
          {(isLargeScreen ? repeatedImages : images).map((src, index) => (
            <motion.img
              key={index}
              src={src}
              alt={`image${index + 1}`}
              className="w-[200px] h-auto mx-5"
              variants={hoverVariant}
              whileHover="hover"
            />
          ))}
        </motion.div>
        <div className="absolute left-0 top-0 h-full w-16 bg-gradient-to-r from-gray-320 to-transparent pointer-events-none"></div>
        <div className="absolute right-0 top-0 h-full w-16 bg-gradient-to-l from-gray-320 to-transparent pointer-events-none"></div>
      </div>
      <style jsx>{`
        .scrolling-container {
          animation: scroll 120s linear infinite;
        }
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </section>
  );
}
