import React, { useState, ChangeEvent } from 'react';
import useResponsive from '@/hooks/useResponsive';
import Select from 'react-select';

interface SelectorProps {
  onChange: (value: string) => void;
  error: string;
}

const PlacementTypeSelector: React.FC<SelectorProps> = ({
  onChange,
  error,
}) => {
  const [selectedPlacementType, setSelectedPlacementType] =
    useState<string>('');
  const { isMobile } = useResponsive();

  const handleChange = (selectedOption: any) => {
    setSelectedPlacementType(selectedOption.value);
    onChange(selectedOption.value);
  };

  const renderFormStyle = () => {
    const path = window.location.pathname;
    let baseClass = 'form-container';
    if (isMobile) {
      baseClass = 'mobile-form-container';
    } else if (path === '/premier-locum-general-practitioner') {
      baseClass = 'locum-form-container1';
    } else if (path === '/premier-locum-doctor' || path === '/spot-workforce') {
      baseClass = 'locum-form-container2';
    }
    return baseClass;
  };

  const placementTypeOptions = [
    { value: 'permanent', label: 'Permanent' },
    { value: 'locum', label: 'Locum' },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      border: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer', // Adding cursor pointer here
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? 'rgba(13, 26, 62, 1)'
        : state.isFocused
          ? 'rgba(28, 155, 221, 1)'
          : null,
      color: state.isSelected || state.isFocused ? '#FFFFFF' : '#515869',
      animation: 'fadeInPositive 0.5s ease-in-out',
    }),
    menu: (provided) => ({
      ...provided,
      backdropFilter: 'blur(10px)',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      marginRight: '8px',
      marginBottom: '4px',
    }),
    menuList: (provided) => ({
      ...provided,
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '0px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#A8a9ad',
        borderRadius: '0px',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#515869',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#515869',
    }),
  };

  return (
    <div
      className={`select-wrapper ${renderFormStyle()} form-text ${
        error ? 'outline-red-300' : 'outline-blue-250'
      }`}
    >
      <Select
        id="placement-type"
        value={placementTypeOptions.find(
          (option) => option.value === selectedPlacementType
        )}
        onChange={handleChange}
        options={placementTypeOptions}
        styles={customStyles}
        placeholder="Placement Type"
      />
    </div>
  );
};

export { PlacementTypeSelector };
