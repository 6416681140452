import DropDownItem from './DropDownItem';
import { QUESTIONS } from '@/constants/FrequentQuestion';

export default function DropDownSection() {
  return (
    <section className="padding-container px-44">
      <h1 className="text-center font-sans font-normal text-[42px] leading-[76px]">
        Q&A
      </h1>
      <div>
        {QUESTIONS.map((item) => (
          <DropDownItem
            key={item.id}
            id={item.id}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </div>
    </section>
  );
}
