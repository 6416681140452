import { FC, useEffect } from 'react';
import { motion } from 'framer-motion';
import Button from '../../shared/button/Button';
import { CardProps } from './type';
import { useNavigate } from 'react-router-dom';
import shareImg from '@/assets/icons/share.png';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import { formatJobNameForURL } from '@/utils/formatJobNameforURL';

interface PermanentJobCardProps extends CardProps {
  onShareClick: (jobUrl: string) => void;
}

const PermanentJobCard: FC<PermanentJobCardProps> = ({
  offer,
  onShareClick,
}) => {
  const navigate = useNavigate();

  const handleNavigateToPermanent = (event: React.MouseEvent) => {
    event.stopPropagation();
    // localStorage.setItem('jobData', JSON.stringify(offer));
    navigate(
      `apply-now/permanent/${formatJobNameForURL(offer.job_title)}/${offer.jobdetails_id}`,
      {
        state: { jobData: offer },
      }
    );
    window.scrollTo(0, 0);
  };

  const handleShareButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    const jobUrl = `${window.location.origin}/apply-now/permanent/${formatJobNameForURL(offer.job_title)}/${offer.jobdetails_id}`;
    onShareClick(jobUrl);
  };

  const handleApplyButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(
      `apply-now/permanent/${formatJobNameForURL(offer.job_title)}/${offer.jobdetails_id}`,
      {
        state: { jobData: offer },
      }
    );
    window.scrollTo(0, 0);
  };

  const getDisplayText = (suburb, state) => {
    if (suburb && state) return `${suburb}, ${state}`;
    if (suburb) return suburb;
    if (state) return state;
    return '';
  };

  return (
    <div
      key={offer.jobdetails_id}
      className="max-w-[355px] max-h-[520px] border-2 mx-0 lg:mx-2 border-blue-300 shadow-md rounded-md relative cursor-pointer"
      onClick={handleNavigateToPermanent}
    >
      <motion.img
        src={offer.job_image}
        alt={offer.title}
        width={355}
        height={194}
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 1 }}
        className="rounded-t-md"
      />
      <div className="absolute top-0 right-4 card-date">
        Post Date: {offer.commencement_date}
      </div>
      <div className="p-3">
        <div className="flex items-center justify-between mb-[6px]">
          <h3 className="card-text-main">{offer.profession?.name}</h3>
          <h3 className="text-gray-480 font-semibold bg-gray-20 border rounded-md border-gray-120 px-[6px] text-sm">
            {offer.job_id}
          </h3>
        </div>
        <p className="card-text-header h-[52px]">{offer.job_title}</p>
        <div className="flex flex-col p-2 card-text-items">
          <div className="flex items-center my-1">
            <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
              <img
                src={`${AWS_BASE_ASSETS_ICONS}CurrencyIcon.png`}
                alt="Salary Icon"
                className="w-[14.8px] h-[14.8px] object-contain"
              />
            </div>
            {offer.billing_share && offer.hourly_fee ? (
              <p className="line-clamp-1">{`${offer.hourly_fee} or ${offer.billing_share}`}</p>
            ) : (
              <p className="line-clamp-1">
                {offer.hourly_fee || offer.billing_share}
              </p>
            )}
          </div>
          <div className="flex items-center  my-1">
            <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
              <img
                src={`${AWS_BASE_ASSETS_ICONS}LocationIcon.png`}
                alt="Salary Icon"
                className="w-[14.8px] h-[15.3px] object-contain"
              />
            </div>
            <p className="line-clamp-1">
              {getDisplayText(offer.suburb?.name, offer.state?.name)}
            </p>
          </div>
          <div className="flex items-center  my-1">
            <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
              <img
                src={`${AWS_BASE_ASSETS_ICONS}ClockIcon.png`}
                alt="Salary Icon"
                className="w-[14.8px] h-[14.8px] object-contain "
              />
            </div>
            <p>{offer.engagement_type?.name}</p>
          </div>
          <div className="flex items-center  my-1">
            <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
              <img
                src={`${AWS_BASE_ASSETS_ICONS}JobTypeIcon.png`}
                alt="Salary Icon"
                className="w-[13.34px] h-[12.13px] object-contain"
              />
            </div>
            <p>Permanent</p>
          </div>
          <div className="flex items-center justify-between mt-2">
            <Button
              type="button"
              title="Share"
              variant={'share-btn'}
              iconSrc={shareImg}
              handleClick={handleShareButtonClick}
            />
            <Button
              type="button"
              title="View More"
              variant="apply-now"
              handleClick={handleApplyButtonClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermanentJobCard;
