import Button from '../shared/button/Button';
import FeedBackCarousel from '../feedback/FeedbackCaresoul';
import { useNavigate } from 'react-router-dom';
import { TESTIMONIALS } from '@/constants/Feedback';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';

export default function GratitudeComponent() {
  const path = location.pathname;
  const navigate = useNavigate();

  const renderBtnColor = () => {
    if (path === '/premier-locum-general-practitioner') {
      return 'locum-gold-btn';
    } else if (path === '/premier-locum-doctor' || path === '/spot-workforce') {
      return 'locum-silver-btn';
    } else {
      return 'locum-gold-btn';
    }
  };

  const navigateToEarn = () => {
    navigate('/register');
    window.scroll(0, 0);
  };

  return (
    <div className="padding-container flex items-center justify-between gap-10">
      <div className="w-[70%]">
        <h3 className="font-sans font-normal text-[42px] leading-[76px] text-blue-520">
          Gratitude
        </h3>
        <h2 className="font-sans font-normal text-[25px] leading-[76px] text-gray-120">
          Best Reviews From Our Clients
        </h2>
        <div className="4xl:w-[1000px]">
          <FeedBackCarousel data={TESTIMONIALS} />
        </div>
      </div>
      <div className="lg:w-[400px] lg:h-[500px] 2xl:w-[490px] 2xl:h-[604px] relative">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#27282bcc] rounded-[8px]"></div>
        <div className="absolute bottom-36 left-10 right-10 z-5">
          <h2 className="font-sans font-bold text-white text-[20px] leading-[28px] text-center">
            Interested in joining New Zealand's healthcare workforce? Let's
            begin!
          </h2>
        </div>
        <img
          src={`${AWS_BASE_URL_ASSETS}/FeedBackImg.jpeg`}
          className="w-full h-full rounded-[8px] object-cover object-center"
          alt="doctor-img"
        />
        <div className="absolute left-10 right-10 bottom-10 z-5">
          <div>
            <Button
              type={'button'}
              title={'Register'}
              variant={'find-jobs-btn'}
              handleClick={navigateToEarn}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
