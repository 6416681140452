import { useState } from 'react';
import Button from '@/components/shared/button/Button';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { verifyEmailFormat } from '@/utils/EmailVerification';
import { isPhoneValid } from '@/utils/PhoneNumberVerification';
import { Link, useNavigate } from 'react-router-dom';
import { validatePassword } from '@/utils/PasswordValidationUtil';
import PopupNotification from '@/components/shared/notification/PopupNotification';
import { IndustryTypeSelector } from '../selectors/IndustryTypesSelector';
import SocialMediaButton from '@/components/shared/button/SocialMediaBtn';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import { SocialMediaSelector } from '../selectors/SocialMediaSelector';
import pdfIcon from '/icons/pdfIcon.png';
import wordIcon from '/icons/wordIcon.png';
import { ProfessionSelector } from '../selectors/ProfessionSelector';
import { PlacementTypeSelector } from '../selectors/PlacementTypeSelector';

export default function EmployerServiceRegistration() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    clientName: '',
    tradeName: '',
    abn: '',
    website: '',
    name: '',
    designation: '',
    email: '',
    phoneNumber: '',
    industryType: '',
    profession: '',
    placementType: '',
    message: '',
    jobDescription: '',
    agree: false,
    subscribe: false,
  });
  const [submit, setSubmit] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [passwordWarning, setPasswordWarning] = useState<string>('');
  const [formErrors, setFormErrors] = useState({
    clientName: '',
    tradeName: '',
    abn: '',
    website: '',
    name: '',
    designation: '',
    email: '',
    phoneNumber: '',
    industryType: '',
    profession: '',
    placementType: '',
    message: '',
    agree: false,
    subscribe: false,
  });

  const allErrors =
    formErrors.clientName ||
    formErrors.tradeName ||
    formErrors.phoneNumber ||
    formErrors.name ||
    formErrors.designation ||
    formErrors.email ||
    formErrors.phoneNumber ||
    formErrors.industryType ||
    formErrors.profession ||
    formErrors.message ||
    formErrors.subscribe ||
    formErrors.agree;

  const handleChange = (e: {
    target: { id: string; type: string; checked: boolean; value: string };
  }) => {
    const { id, type, checked, value } = e.target;
    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value,
    });
  };

  const allowedFileTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg', // JPG
    'image/png', // PNG
    'image/gif', // GIF
    'image/bmp', // BMP
    'image/tiff', // TIFF
    'image/webp',
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (allowedFileTypes.includes(file.type)) {
        setFile(file);
      } else {
        alert(
          'Please upload a valid file. Only PDF, DOCX, and image files are allowed.'
        );
      }
    }
  };

  const getIconForFileType = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension === 'pdf') {
      return <img src={pdfIcon} alt="PDF Icon" className="w-10 h-10 mr-2" />;
    } else if (['txt', 'doc', 'docx'].includes(extension || '')) {
      return <img src={wordIcon} alt="Text Icon" className="w-10 h-10 mr-2" />;
    } else {
      return null;
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
    if (
      !formData.companyName ||
      !formData.tradeName ||
      !formData.businessNumber ||
      !formData.industryType ||
      !formData.phoneNumber ||
      !formData.designation ||
      !formData.country ||
      !formData.work ||
      !formData.website ||
      !formData.agree
    ) {
      setSubmit(false);
      const newErrors = {
        companyName: !formData.companyName ? 'Please enter Company Name' : '',
        tradeName: !formData.tradeName ? 'Please enter your Trade Name' : '',
        businessNumber: !formData.businessNumber
          ? 'Please enter your Business Number'
          : '',
        website: !formData.website ? 'Please enter your website URL' : '',
        industryType: !formData.industryType
          ? 'Please select Industry Type'
          : '',
        phoneNumber: !formData.phoneNumber
          ? 'Please enter your Phone Number'
          : !isPhoneValid(formData.phoneNumber)
            ? 'Please enter a valid Phone Number'
            : '',
        designation: !formData.designation
          ? 'Please enter your Designation'
          : '',
        country: !formData.country ? 'Please enter Country' : '',
        work: !formData.work ? 'Please enter your Work' : '',
        email: !formData.email
          ? 'Please enter your email address'
          : !verifyEmailFormat(formData.email)
            ? 'Please enter a valid email address'
            : '',
        agree: !formData.agree
          ? 'You must agree to the Terms and Conditions and Privacy Policy'
          : '',
      };
      setFormErrors(newErrors);
      setTimeout(
        () =>
          setFormErrors({
            companyName: '',
            tradeName: '',
            businessNumber: '',
            industryType: '',
            phoneNumber: '',
            designation: '',
            country: '',
            work: '',
            website: '',
            social: '',
            email: '',
            password: '',
            agree: false,
            subscribe: false,
          }),
        3000
      );
    }
    const passwordError = validatePassword(formData.password);
    if (passwordError) {
      setPasswordWarning(passwordError);
      setTimeout(() => setPasswordWarning(''), 3000);
      setSubmit(false);
    }
    // setSubmit(true);
    setFormData({
      clientName: '',
      tradeName: '',
      abn: '',
      website: '',
      name: '',
      designation: '',
      email: '',
      phoneNumber: '',
      industryType: '',
      profession: '',
      placementType: '',
      message: '',
      jobDescription: '',
      agree: false,
      subscribe: false,
    });
  };

  const NavigateToCandidateRegistration = () => {
    navigate('/register');
  };

  return (
    <div
      className="relative padding-container flex items-center justify-center"
      id="register-form"
    >
      <form
        onSubmit={handleSubmit}
        className="lg:p-[40px] xl:p-[70px] lg:w-[900px] xl:w-[1054px] drop-shadow-2xl bg-white rounded-lg"
        noValidate
      >
        <div className="text-center font-sans font-normal text-[42px] leading-[76px] mb-16">
          Employer Registration
        </div>
        <div className="flex items-center justify-between gap-6 mb-10">
          <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
            <input
              type="text"
              id="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className={`form-container input-placeholder ${formErrors.companyName ? 'outline-red-300' : 'outline-blue-250'}`}
              autoComplete="off"
              placeholder="Client Name"
            />
            {formErrors.companyName && (
              <div className="form-error-msg">{formErrors.companyName}</div>
            )}
          </div>
          <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
            <input
              type="text"
              id="tradeName"
              value={formData.tradeName}
              onChange={handleChange}
              className={`form-container input-placeholder ${formErrors.tradeName ? 'outline-red-300' : 'outline-blue-250'}`}
              autoComplete="off"
              placeholder="Trade Name"
            />
            {formErrors.tradeName && (
              <div className="form-error-msg">{formErrors.tradeName}</div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between gap-6 mb-10">
          <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
            <input
              type="text"
              id="businessNumber"
              value={formData.businessNumber}
              onChange={handleChange}
              className={`form-container input-placeholder ${formErrors.businessNumber ? 'outline-red-300' : 'outline-blue-250'}`}
              autoComplete="off"
              placeholder="ABN Number"
            />
            {formErrors.businessNumber && (
              <div className="form-error-msg">{formErrors.businessNumber}</div>
            )}
          </div>
          <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
            <input
              type="text"
              id="website"
              value={formData.website}
              onChange={handleChange}
              className={`form-container input-placeholder ${formErrors.website ? 'outline-red-300' : 'outline-blue-250'}`}
              autoComplete="off"
              placeholder="Website URL"
            />
            {formErrors.website && (
              <div className="form-error-msg">{formErrors.website}</div>
            )}
          </div>
        </div>
        <p className="font-sans font-normal text-base leading-[31px] text-gray-400 my-6">
          Official Contact
        </p>
        <div className="flex items-center justify-between gap-6 mb-10">
          <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
            <input
              type="text"
              id="name"
              value={formData.designation}
              onChange={handleChange}
              className={`form-container input-placeholder ${formErrors.designation ? 'outline-red-300' : 'outline-blue-250'}`}
              autoComplete="off"
              placeholder="Name"
            />
            {formErrors.designation && (
              <div className="form-error-msg">{formErrors.designation}</div>
            )}
          </div>
          <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
            <input
              type="text"
              id="designation"
              value={formData.designation}
              onChange={handleChange}
              className={`form-container input-placeholder ${formErrors.designation ? 'outline-red-300' : 'outline-blue-250'}`}
              autoComplete="off"
              placeholder="Designation"
            />
            {formErrors.designation && (
              <div className="form-error-msg">{formErrors.designation}</div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between gap-6 mb-10">
          <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className={`form-container input-placeholder ${formErrors.email ? 'outline-red-300' : 'outline-blue-250'}`}
              autoComplete="off"
              placeholder="Email"
            />
            {formErrors.email && (
              <div className="form-error-msg">{formErrors.email}</div>
            )}
          </div>
          <div className="flex items-center justify-between  relative lg:min-w-[400px] xl:min-w-[442px]">
            <PhoneInput
              forceDialCode={true}
              defaultCountry="au"
              countrySelectorStyleProps={{
                buttonStyle: {
                  zIndex: '30',
                  position: 'absolute',
                  left: '0px',
                  height: '38px',
                  width: '56px',
                  border: 'none',
                  backgroundColor: '#FFFFFF',
                },
              }}
              onChange={(phone) =>
                setFormData({ ...formData, phoneNumber: phone })
              }
              inputProps={{
                id: 'phoneNumber',
                autoComplete: 'off',
                className: 'w-full relative pl-16 focus:outline-none',
              }}
              className={`form-container input-placeholder ${formErrors.phoneNumber ? 'outline-red-300' : 'outline-blue-250'}`}
              value={formData.phoneNumber}
            />
            {formErrors.phoneNumber && (
              <div className="text-red-100 absolute text-sm mt-[84px]">
                {formErrors.phoneNumber}
              </div>
            )}
          </div>
        </div>
        <p className="font-sans font-normal text-base leading-[31px] text-gray-400 my-6">
          Main Details
        </p>
        <div className="mb-10">
          <IndustryTypeSelector
            onChange={(value) =>
              setFormData({ ...formData, industryType: value })
            }
            error={formErrors.tradeName}
            title="Healthcare Industry Type"
          />
          {formErrors.industryType && (
            <div className="form-error-msg">{formErrors.industryType}</div>
          )}
        </div>
        <div className=" mb-10">
          <ProfessionSelector
            onChange={(value) =>
              setFormData({ ...formData, profession: value })
            }
            error={formErrors.profession}
            title="Professional That you are looking to recruit?"
          />
          {formErrors.profession && (
            <div className="form-error-msg">{formErrors.profession}</div>
          )}
        </div>
        <div className=" mb-10">
          <PlacementTypeSelector
            onChange={(value) =>
              setFormData({ ...formData, profession: value })
            }
            error={formErrors.profession}
            title="Professional That you are looking to recruit?"
          />
          {formErrors.profession && (
            <div className="form-error-msg">{formErrors.profession}</div>
          )}
        </div>
        <p className="font-sans font-normal text-base leading-[31px] text-gray-400 my-6">
          Message
        </p>
        <div className="mb-10">
          <textarea
            id="note"
            value={formData.note}
            onChange={handleChange}
            className="form-text-area input-placeholder outline-blue-250"
            autoComplete="off"
            placeholder=""
            rows={4}
          />
        </div>
        <div className="mb-10">
          <div className="relative w-full h-[115px] p-2 px-3 bg-gray-610 outline rounded-sm outline-[1px] outline-blue-250 focus:outline focus:outline-blue-400">
            <div className=" outline-blue-250" onChange={handleFileChange}>
              <label htmlFor="fileUpload" className=" w-full cursor-pointer">
                <div className=" ">
                  {file ? (
                    <div className="flex items-center text-nowrap justify-center">
                      <div>{getIconForFileType(file.name)}</div>
                      <h1 className="form-text">{file.name}</h1>
                    </div>
                  ) : (
                    <p className="form-text mt-[16px]">
                      <div className="text-center">
                        <img
                          src={`${AWS_BASE_ASSETS_ICONS}CloudUploadFill.png`}
                          width={23}
                          height={23}
                          className="mx-auto"
                        />
                        <span className="mt-2">
                          You May Upload your Job Description Here
                        </span>
                      </div>
                    </p>
                  )}
                </div>
                <input
                  id="fileUpload"
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="mb-32 mt-10 ">
          <div className="mb-5 mt-10">
            <div className="relative">
              <input
                type="checkbox"
                id="agree"
                checked={formData.agree}
                onChange={handleChange}
              />
              <label
                htmlFor="agree"
                className={`relative ${
                  formErrors.agree
                    ? 'after:text-red-100'
                    : 'after:text-gray-900'
                } after:content-['*'] after:ml-1  absolute top-0 left-3`}
              >
                I Agree to the{' '}
                <Link to={'/terms-and-conditions'} className="text-blue-220">
                  Term and Condition
                </Link>{' '}
                and{' '}
                <Link to={'/privacy-policy'} className="text-blue-220">
                  Privacy policy
                </Link>
              </label>
              {formErrors.agree && (
                <div className="absolute text-red-100 text-sm ml-5">
                  {formErrors.agree}
                </div>
              )}
            </div>
          </div>

          <div>
            <input
              type="checkbox"
              id="subscribe"
              checked={formData.subscribe}
              onChange={handleChange}
              className=""
              required
            />
            <label htmlFor="subscribe" className="ml-2">
              Register for Candidate Alerts
            </label>
          </div>
        </div>
        <div className="absolute bottom-[100px] right-[70px] flex flex-col items-center">
          <div className="w-[236px]">
            <Button type="submit" title="Submit" variant="find-jobs-btn" />
          </div>
        </div>
        <div className="absolute bottom-[30px] right-[70px] left-[70px]">
          {allErrors && (
            <div className="w-full border p-3 rounded-[4px] text-center text-sm font-normal font-sans text-red-200 border-red-400 bg-red-500 bg-opacity-40">
              Please Enter Required Field above
            </div>
          )}
        </div>
      </form>
      {submit && (
        <div>
          <PopupNotification
            title={'Registered Successful'}
            message={
              'Thank you for registering with us. Please verify yourself by clicking on verification link on your mail.'
            }
            onClose={function (): void {
              throw new Error('Function not implemented.');
            }}
            type={'success'}
          />
        </div>
      )}
    </div>
  );
}
