import { LogoProps } from '@/types/types';
import logoImage from '/images/Medfuture-logo-blue.png';

export default function LogoCommon({ height, width, handleClick }: LogoProps) {
  return (
    <header>
      <div className="w-[300px] h-[80px]">
        <img
          src={logoImage}
          height={height}
          width={width}
          loading="lazy"
          alt="Logo"
          className="cursor-pointer object-contain h-full w-full object-center"
          onClick={handleClick}
        />
      </div>
    </header>
  );
}
