import React, { useState, ChangeEvent } from 'react';
import useFetchStatesWithRegions from '@/hooks/useFetchStatewithRegions';
import useResponsive from '@/hooks/useResponsive';

interface ProfessionSelectorProps {
  onChange: (value: string) => void;
  error: string;
}

const ProfessionalStatusSelector: React.FC<ProfessionSelectorProps> = ({
  onChange,
  error,
}) => {
  const [selectedProfession, setSelectedProfession] = useState<string>('');
  const { data, loading, error: fetchError } = useFetchStatesWithRegions();
  const { isMobile } = useResponsive();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (fetchError) {
    return <div>Error: {fetchError.message}</div>;
  }

  const handleProfessionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const profession = e.target.value;
    setSelectedProfession(profession);
    onChange(profession);
  };

  const renderFormStyle = (hasError: boolean) => {
    const path = location.pathname;
    let baseClass = isMobile ? 'mobile-form-container' : 'form-container';

    if (path === '/premier-locum-general-practitioner') {
      baseClass = `locum-form-container ${hasError ? 'outline-red-300' : 'outline-blue-560'}`;
    } else if (
      path === '/premier-locum-doctor' ||
      path === '/spot-workforce' ||
      path === '/nursing'
    ) {
      baseClass = `locum-form-container ${hasError ? 'outline-red-300' : 'outline-white'}`;
    } else {
      baseClass = `${baseClass} ${hasError ? 'outline-red-300' : ''}`;
    }

    return baseClass;
  };
  return (
    <select
      id="profession_status"
      className={`select-wrapper ${renderFormStyle(!!error)} form-text`}
      onChange={handleProfessionChange}
      value={selectedProfession || ''}
    >
      <option value="">Professional Status</option>
      {data?.map((profession) => (
        <option
          key={profession.profession_id}
          value={profession.profession_id.toString()}
        >
          {profession.name}
        </option>
      ))}
    </select>
  );
};

export { ProfessionalStatusSelector };
